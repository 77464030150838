/* member */
#header nav.navbar.auth a,
#header nav.navbar.auth button.nav-link,
#header nav.navbar.auth button.dropdown-toggle { color:var(--bs-white); }

#masthead.alt button:not(.dropdown-item) .theme-icon { background-color:var(--bs-white); }
#masthead.alt button:not(.dropdown-item).show { color: var(--bs-white); }
#masthead.alt { background:#243842;color:#fff;box-shadow:none; }

.modal.member .modal-body { padding:0;}
.modal.member .container { max-width:var(--max-width); }

.modal.member .modal-body > section:first-child {
	background:#243842;
	padding-top:var(--header-height);
}
.modal.member .modal-body > section:first-child .container { display:flex;align-items:center;margin-top:10rem; }

.modal.member .slant {
	position:absolute;
	width:calc(100% + 2px);
	height:380px;
	bottom:-2px;
	left:-2px;
	mask-size:cover;
	mask-position:left top;
	mask-repeat:no-repeat; 
	mask-image: url("https://s.cogle.io/i/slant.svg");
	background-color:var(--theme-bg);

}
.modal.member form { 
	max-width:480px;min-width:260px; 
	border-radius:0.5rem;background:var(--theme-bg);-webkit-box-shadow:0 15px 30px 0 rgba(0, 0, 0, 0.1);box-shadow:0 15px 30px 0 rgba(0, 0, 0, 0.1);
}
#sign-in.modal.member form {margin-right:2rem;}

.modal.member .panel { margin-right:2rem;color:var(--theme-fg); }
.modal.member .panel form { padding:0.75rem 0.5rem 0 0.5rem;}
.modal.member .panel form { padding:1rem;margin-bottom:1rem;}
.modal.member .panel form + div { display:flex;align-items:baseline;}


.modal.member form fieldset > legend { display:none; }
#sign-in.modal.member form button[type="submit"] { position:relative;left:1rem;top:0.5rem;border:1px solid red;width:80px;height:80px;border-radius:50%;padding:0; }
#sign-up.modal.member form button[type="submit"] { margin-top:0.75rem; }
.modal.member form .input-group { margin-bottom:0.75rem; }
.modal.member form .input-group .input-group-text:first-child { min-width:7rem; }

#sign-in.modal.member ul { display:flex;list-style-type:none;margin:0;padding:0;max-width:96%; }
#sign-in.modal.member form ul > li:first-child { flex:1 1 auto; }
#sign-in.modal.member form ul > li:last-child { width:2rem;max-width:2rem; }

.modal.member .panel h1 { color:#fff; }
.modal.member .panel h1 + p { color:var(--bs-secondary); }

.modal.member section { position:relative;margin-bottom:4rem; }
.modal.member section > .container { position:relative;z-index:2; }
.modal.member section > .container  + .slant { z-index:1; }

.modal.member .error .alert { padding:0.5rem 0.75rem; margin-top:0.5rem; }

.modal.member .modal-footer { justify-content:center;position:fixed;bottom:0;width:100%;padding:0; }
.modal.member .modal-footer .copyright { width:100%;text-align:center;padding-top:1rem; }
.copyright button { border:0;background:transparent;color:var(--cogle-color);font-weight:bold;}

section.social { margin-top:4rem;display:flex;justify-content:center; }
section.social ul { display:flex;justify-content:center;align-items:center;width:var(--max-width); }
section.social ul li { margin-right:0.5rem; }

.social button.sns { border-radius:50%;width:3.5rem;height:3.5rem;background-size:cover;background-repeat:no-repeat;border:0;
  box-shadow: 0 4px 8px rgba(var(--theme-fg-rgb), 0.2), 0 0 10px rgba(var(--theme-bg-rgb), 0.5);
}
.social button.sns:hover { transform: scale(1.05); }
.social button.sns.google { background-image:url("https://s.cogle.io/i/social/google.png"); }
.social button.sns.twitter { background-image:url("https://s.cogle.io/i/social/twitter.png"); }
.social button.sns.facebook { background-image:url("https://s.cogle.io/i/social/facebook.png"); }
.social button.sns.kakao { background-image:url("https://s.cogle.io/i/social/kakaostory.png"); }
.social button.sns.naver { background-image:url("https://s.cogle.io/i/social/band.png"); }

.modal.member .divider { text-align:center; }
.modal.member .divider > hr {
	border: 0;
	height: 1px;
	background-image: linear-gradient(to right, rgba(var(--theme-fg-rgb),0),rgba(var(--theme-fg-rgb),0.75), rgba(var(--theme-fg-rgb),0));
	position: relative;
	top: 1rem;
} 

.modal.member .divider em {
	position:relative;
	top:-0.9rem;
	background:var(--theme-bg);
	font-size:0.9rem;
	padding:0 0.75rem;
	color:#999;
}

button.social { display:flex;align-items:center;width:100%;margin-bottom:0.5rem;color:var(--bs-gray-500); }
button.social i { display:inline-block;width:24px;height:24px;background:transparet 0 0 no-repeat;background-size:cover;margin-right:0.5rem; }

button.social i.google { background-image:url("https://s.cogle.io/i/social/google.svg");width:18px;height:18px; }
button.social i.facebook { background-image:url("https://s.cogle.io/i/social/facebook.svg"); }
button.social i.kakao { background-image:url("https://s.cogle.io/i/social/kakaotalk.png"); }
button.social i.naver { background-image:url("https://s.cogle.io/i/social/naver.png"); }
button.social i.apple { background-image:url("https://s.cogle.io/i/social/apple.svg"); }



/*
.modal.member .container.auth { background:#243842;max-width:100%;padding:0;margin:0;position:relative; }
.modal.member section.sign-in { padding:8rem 0; }

.modal.member .container.auth section { display:flex; }
.modal.member section.member > div { flex-basis:50%; }
.modal.member section.member .form-check { margin:0.75rem 0.25rem; }

.modal.member section.member form { border-radius:0.5rem;background:var(--theme-bg);-webkit-box-shadow:0 15px 30px 0 rgba(0, 0, 0, 0.1);box-shadow:0 15px 30px 0 rgba(0, 0, 0, 0.1);}
.modal.member section.member form[name="login"] button[type="submit"] { position:relative;left:0.4rem;top:0.5rem;border:1px solid red;width:80px;height:80px;border-radius:50%;padding:0; }

.modal.member section.member h1 { margin-top:6rem;margin-bottom:1.5rem;color:#fff; }
.modal.member section.member p.lead { margin-top:1rem;margin-bottom:1rem;color:#fff; }
.modal.member section.member form .form-group {margin-bottom:0; }
.modal.member section.member form[name="login"] .form-group.submit { width:2rem;min-width:2rem;max-width:2rem; }
.modal.member section.member form legend { display:none; }

.modal.member span.input-group-text { min-width:7rem; }
.modal.member form[name="sign-up"] .input-group-append[data-toggle="view"] { cursor:pointer; }
.modal.member .modal-body { overflow:hidden;overflow-y:auto;margin-bottom:4rem; }
.modal.member .modal-footer { justify-content:center;position:fixed;bottom:0;width:100%;padding:0; }
.modal.member .modal-footer .copyright {  width:100%;text-align:center;padding-top:1rem; }


.modal.member section.member .auth { position:relative;z-index:2;padding-right:1.5rem; }
.modal.member section.member .hero { position:relative;right:0;z-index:9; }
.modal.member section.member.sign-up .hero { top:4rem; }
.modal.member section.member .form-group > .input-group:not(:last-child) {margin-bottom:0.5rem;}

@media (max-width:991.98px) {
	.modal.member section.member .container > div { flex-basis:99%; }
	.modal.member section.member form { width:100%; }
	.modal.member section.member form[name="login"] { width:auto; }
	.modal.member section.member .hero { bottom:-80px;margin-bottom:80px; } 
	.modal.member .container.auth section { flex-direction:column;align-items:center; }
	.modal.member .container.auth section > div.auth { max-width:90%; }
	.modal.member section.sign-in { padding-top:2rem; }
	.modal.member .hero { display:none; }


}
.modal.member .container.w-100 .slant {
	position:absolute;
	width:calc(100% + 2px);
	height:380px;
	bottom:-2px;
	left:-2px;
	z-index:1;
	mask-image: url("https://s.cogle.io/i/slant.svg");
	mask-size:cover;
	mask-position:left top;
	mask-repeat:no-repeat; 
	background:var(--theme-bg);
}

*/

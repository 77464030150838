/* typography */
@charset "UTF-8";

@font-face {
    font-family: "oh";
    src: 
			url("../../public/fonts/oh.otf") format("opentype"),
			url("../../public/fonts/oh.ttf") format("truetype"),
			url("../../public/fonts/oh.woff2") format("woff2"),
			url("../../public/fonts/oh.woff") format("woff"),
			url("../../public/fonts/oh.eot?10si43#iefix") format("embedded-opentype"),
			url("../../public/fonts/oh.svg#oh") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "oh";
    src: 
			url("../../public/fonts/ohb.otf") format("opentype"),
			url("../../public/fonts/ohb.ttf") format("truetype"),
			url("../../public/fonts/ohb.woff2") format("woff2"),
			url("../../public/fonts/ohb.woff") format("woff"),
			url("../../public/fonts/ohb.eot?10si43#iefix") format("embedded-opentype"),
			url("../../public/fonts/ohb.svg#oh") format("svg");
    font-weight: bold;
    font-style: normal;
}

/* global */
html { scroll-behavior:smooth;}
@media screen and (prefers-reduced-motion:reduce) { html {scroll-behavior:auto;} }
*, *::before, *::after { box-sizing: border-box; }

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
	margin-top:0;
	margin-bottom:0.5rem;
	font-weight:600;
}

h1, .h1 { font-size: calc(1.3rem + 0.6vw); }
h2, .h2 { font-size: calc(1.275rem + 0.3vw); }
h3, .h3 { font-size: calc(1.26rem + 0.12vw); }
h4, .h4 { font-size: 1.25rem; }
h5, .h5 { font-size: 1.15rem; }
h6, .h6 { font-size: 1.075rem; }

@media (min-width: 1200px) {
	h1, .h1 {font-size: 1.75rem;}
	h2, .h2 {font-size: 1.5rem; }
	h3, .h3 { font-size: 1.35rem; }
}
.container { max-width:100%; }

body {
	margin:0;
	font-family: var(--bs-font-sans-serif);
	font-size:var(--bs-body-font-size);
	/* color:#211f1c;rgba(33,31,28,1); */
	color:var(--bs-body-color);/*:#212529; */
	background-color:var(--bs-body-bg);
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {color: var(--bs-link-color);text-decoration: none;transition:.3s all ease;}
a:hover {color: var(--bs-link-hover-color);}
a:not([href]):not([class]), a:not([href]):not([class]):hover {color: inherit;}


.empty {min-height:50vh;background:url("https://s.cogle.io/i/ghost.svg") no-repeat 2% 50%;background-size:35% 35%; }
.empty, .fallback { display:flex;align-items:center;text-align:center;color:#475f7b;position:relative;}
.empty > .alert {width:80%;display:flex;justify-content:center;font-size:1.2rem;color:var(--theme-fg); }
.fallback {background:url("https://s.cogle.io/i/ghost.svg") no-repeat center;background-size:50% 50%; }
.ellipsis { text-overflow:ellipsis;overflow:hidden;white-space:nowrap; }


#main { max-width:var(--max-width);flex:1 1 auto; }
#main > section { margin:1rem;width:100%; }

#header { height:var(--header-height);}
#header + .container { display:flex;padding:0;}
.app.mobile #header + .container { flex-direction:column; }

#masthead { position:fixed;top:0;width:100vw;display:flex;background:var(--theme-bg);box-shadow:1px 1px 1px rgba(192,192,192,0.3);align-items:center;padding:0;height:48px;z-index:9999; }
#header a.logo { background:url("https://s.cogle.io/i/logo.png") no-repeat 1rem 50%;background-size:120px 32px;color:transparent;display:block;width:240px;height:50px;margin:0;padding:0; }
#masthead ul.navbar-nav { justify-content:end;flex:1 1 auto;align-items:center; }
#masthead ul.navbar-nav button:not(.reset) { display:flex;align-items:center;}

#header li.dropdown-item > a,
#header li.dropdown-item > button { width:100%;padding:0;text-align:left; }
#header li.dropdown-item button > .flag { padding:0.4rem 0.25rem; }
#header button.dropdown-item.on { color:var(--cogle-color); }
#header button.dropdown-item.on .theme-icon { background-color:var(--cogle-color); }

#header .dropdown-item > svg,
#header .dropdown-item > .theme-icon { margin-right:0.35rem; }
#header .dropdown-item .email { display:inline-block;width:100%;text-align:center;max-width:11rem;overflow:hidden;text-overflow:ellipsis; }

#main section.lost + #footer { display:none; }
#footer { margin:4rem 0 2rem 1rem;border-top:1px solid rgba(var(--theme-fg-rgb),0.2);padding-top:1rem; }
#footer .container { display:flex;align-items:center;margin-bottom:0.75rem;padding:0; }
#footer .container img.logo { width:98px;margin-right:1rem;}
#footer .container ul { display:inline-flex;gap:0.5rem;}
#footer .container > ul:last-child { flex:1 1 auto;justify-content:flex-end;}

#footer ul.about li { display:inline-flex;align-items:center; }
#footer ul.about li::after { content:"•";margin-left:0.5rem;color:rgba(var(--theme-fg-rgb),0.3) }
#footer ul.about li:last-child::after { content:"";}

section.about > h1 { border-bottom:2px solid firebrick;padding-bottom:0.5rem;margin-bottom:2rem; }
section.about > h1 + .container{ padding:0.5rem; }
section.about .container  h3 { font-size:1.2rem }
section.about .container h4 { font-size:1.1rem }
section.about .container h5 { font-size:1rem }
section.about .container h3 small,
section.about .container h4 small,
section.about .container h5 small { font-weight:normal; }

#footer .copyright  { display:inline-flex;align-items:center;gap:0.4rem; }
#footer .copyright strong { color:var(--cogle-color); }
#footer .copyright strong > small { color:var(--bs-body-color);font-weight:normal; }


#footer .sns  a { 
	display:inline-block;
	width:1.5rem;height:1.5rem;
	border-radius:50%;
	background-size:cover;background-repeat:no-repeat;
	border:0;
  box-shadow: 0 4px 8px rgba(var(--theme-fg-rgb), 0.2), 0 0 10px rgba(var(--theme-bg-rgb), 0.5);
}
#footer .sns a:hover { transform: scale(1.25); }
#footer .sns a.google { background-image:url("https://s.cogle.io/i/social/google.png"); }
#footer .sns a.twitter { background-image:url("https://s.cogle.io/i/social/twitter.png"); }
#footer .sns a.facebook { background-image:url("https://s.cogle.io/i/social/facebook.png"); }
#footer .sns a.kakao { background-image:url("https://s.cogle.io/i/social/kakaostory.png"); }
#footer .sns a.naver { background-image:url("https://s.cogle.io/i/social/band.png"); }

.navbar-brand { height:48px;width:120px; }
.navbar-brand > img { width:100%;height:100%; }

#aside {margin-left:1rem;width:var(--aside-width); }


form.search {display:flex;justify-content:center;}
form.search > form {margin:0 auto;width:100%;display:flex;position:relative;align-items:stretch;}

form.search input[name="q"] {
	flex:1 1 auto;
	padding: 0.1rem 0.1rem 0.1rem 1rem;
	max-width:2rem;
	border: 1px solid gray;
	transition: all 0.3s ease;
	outline: none;
	text-decoration:underline;
	border-radius:100px;
	border-right:0;
	border-top-right-radius:0;
	border-bottom-right-radius:0;
	text-decoration-color: red;
	text-decoration-thickness: .15rem;
	text-underline-offset: .1em;
}

#sidebar.mobile form.search input[name="q"] {padding: 0.25rem 0.1rem 0.25rem 1rem; }


form.search .reset {padding:0 0.2rem;display:none;}
form.search.expanded .reset {display:block;border:1px solid gray;border-left:0;border-radius:0;border-right:0;}
form.search button[type="submit"] { padding:0 0.2rem;border:1px solid gray;border-radius:100px;border-left:0;border-top-left-radius:0;border-bottom-left-radius:0;}
form.search.expanded input[name="q"] { min-width:10rem;width:100%;text-decoration-color:transparent; }
form.search.expanded input[name="q"].prompt { animation:prompt 0.8s steps(2, end) infinite; }
form.search.expanded input[name="q"]:focus { box-shadow:none;outline:none; }
@keyframes prompt { 0%, 100% {text-decoration-color: transparent;}50% {text-decoration-color:red; }}

/*
.theme-switcher button:hover,
.theme-switcher button:focus {
	background:rgba(var(--theme-fg-rgb),0.1);
}
*/
.theme-icon, .nav-icon { margin-right:0.25rem; }
.theme-icon { width:var(--bs-body-font-size);height:var(--bs-body-font-size);display:inline-block;background-color:var(--theme-fg);mask-repeat:no-repeat;mask-size:cover; }
.theme-icon.system { mask-image:url("https://s.cogle.io/i/theme/system.svg"); }
.theme-icon.dark { mask-image:url("https://s.cogle.io/i/theme/dark.svg"); }
.theme-icon.light { mask-image:url("https://s.cogle.io/i/theme/light.svg"); }


#sidebar { 
	width:var(--sidebar-width);
	max-width:var(--sidebar-width);
	min-width:var(--sidebar-width);
	background-color:var(--theme-bg);
	font-size: 0.9rem;
	z-index: 100;
}
#sidebar a:hover.nav-link { background:rgba(var(--theme-fg-rgb),0.03); }

#sidebar.collapsed { 
	width:var(--sidebar-collapsed-width);
	min-width:var(--sidebar-collapsed-width);
	max-width:var(--sidebar-collapsed-width);
}

#sidebar.collapsed .container { overflow:visible; }

#sidebar > .container { position:fixed;padding:0;z-index:110;top:var(--header-height);background:var(--card-bg);box-shadow: 0 0 2px darkgray;width:var(--sidebar-width);max-width:var(--sidebar-width);bottom:0;max-height:cal(100vh - var(--header-height));overflow:hidden;overflow-y:auto;padding-bottom:4rem; }
#sidebar.collapsed:not(.mobile) > .container { width:var(--sidebar-collapsed-width);max-width:var(--sidebar-collapsed-width); }

#sidebar.mobile { position:fixed;height:100vh;right:0; transform:translateX(100%); transition:transform 0.3s ease-in-out; }
#sidebar.mobile.collapsed { transform: translateX(100%); }
#sidebar.mobile.expanded { transform: translateX(0); }
#sidebar.mobile > .container { position:relative;top:0;width:var(--sidebar-width);height:100%;max-height:cal(100vh - var(--header-height));overflow-x: hidden;overflow-y: auto;padding-bottom:8rem; }


#sidebar.mobile button[data-bs-target="#sign-in"] { width:100%;display:flex;align-items:center;justify-content:center;border:0;padding:0.75rem; }
#sidebar.mobile button.sign-out svg { margin-right:0.25rem; }
#sidebar.mobile .profile { display:flex;justify-content:center;margin-bottom:0.75rem;align-items:center; }
#sidebar.mobile .profile ul.me { padding:0;margin-top:0.5rem; }
#sidebar.mobile .profile ul.me li { padding:0; }
#sidebar.mobile .profile ul.me .ellipsis { display:inline-block;max-width:9rem;overflow:hidden;text-overflow:ellipsis;padding:0;}
#sidebar.mobile .profile ul.me button { padding:0;}
#sidebar.mobile a.dash { margin-right:1rem;}

#sidebar:not(.collapsed) li.dropend > .nav a { border-bottom:1px dashed rgba(var(--theme-fg-rgb),0.2)}
#sidebar:not(.collapsed) li.dropend li { position:relative; }
/* 
#sidebar:not(.collapsed) li.dropend li::before { font-family: 'Material Symbols Outlined';content:'subdirectory_arrow_right';position:absolute;top:0.5rem;left:-0.7rem;color:#d0d0d0; } 
#sidebar.collapsed li.dropend li.dropend > .nav > a::after { font-family: 'Material Symbols Outlined';content:"chevron_right"; transition: transform 0.3s ease; }
 */

#sidebar.collapsed button.accordion-button { display:none; }
#sidebar.collapsed ul.navbar {flex-direction:column;}
#sidebar.collapsed li.nav-item { width:100%; }
#sidebar.collapsed a.nav-link.icon { overflow:hidden;display:flex;flex-direction:column;justify-content:center;align-items:center; }
#sidebar.collapsed a.nav-link.icon > span { padding-top:0.2rem; }
#sidebar.collapsed .dropdown-menu a.nav-link.icon { flex-direction:row;justify-content:flex-start; }
#sidebar.collapsed .dropdown-menu a.nav-link.icon > i { margin-right:0.15rem; }
#sidebar.collapsed a.nav-link:focus-visible { outline:none; }
#sidebar.collapsed ul { list-style-type:none;padding:0;margin:0; }
#sidebar.collapsed .dropdown-menu { padding:0;margin:0;border-radius:0;min-width:12rem; }
#sidebar.collapsed ul#side-nav > li > a,
#sidebar.collapsed ul#side-nav > li > .nav > a { font-size:0.78rem; }


#sidebar.collapsed .nav-item.dropend > ul { position:absolute;top:0;right:auto;left:100%;margin-top:0;margin-left:-0.1rem; z-index:1000;}
#sidebar.collapsed .nav-item.dropend a { white-space:nowrap; }
#sidebar.collapsed .nav-item.dropend:hover > ul,
#sidebar.collapsed .nav-item.dropend:focus > ul,
#sidebar.collapsed .nav-item.dropend:active > ul {transition-delay:0.5s;visibility:visible;opacity:1;display:block;}

#sidebar.collapsed li.dropend li.dropend > .nav > a {
	width:100%;display:flex;justify-content:space-between;
}
#sidebar.collapsed li.dropend li.dropend > .nav > a:hover::after,
#sidebar.collapsed li.dropend li.dropend.on > .nav > a::after { transform: rotate(90deg);}

li.nav-item.dropend.on > div a.nav-link {color:var(--bs-link-hover-color);}

#sidebar ul { list-style-type:none;padding-left:1.25rem; }

#sidebar .nav-item a.nav-link { padding:0.5rem;}
#sidebar .nav-item > .nav a { flex-grow:1;}
#side-nav .nav-item > .nav { justify-content:space-between;align-items: center; }
#side-nav .nav-item button.accordion-button { background-color: transparent;width:var(--bs-accordion-btn-icon-width);height:var(--bs-accordion-btn-icon-width);margin-right:1rem; outline: none; border: none; box-shadow: none; }
#sidebar.mobile #side-nav .nav-item button.accordion-button { padding-right:2rem; }

#sidebar.collapsed:not(.mobile) li.dropend li.dropend:after { content:" ";
	width:12px;height:12px;border:1px solid red;
	position:absolute;top:0.5rem;right:0.5rem;background-color:var(--theme-fg);
	transition: transform 0.3s ease;
	mask-image:url("https://s.cogle.io/i/icon/next.svg");
	mask-repeat:no-repeat;
	mask-size:cover;
	background-size:cover;}
#sidebar.collapsed:not(.mobile) li.dropend li.dropend:hover:after { transform: rotate(180deg); }

#side-nav.nav { flex-direction:column; }


#side-nav a.nav-link.on { /*background-color:rgba(var(--theme-fg-rgb),0.03);*/color:var(--cogle-color); }
time.ago:after { content:"전";font-size:87.5%;color:var(--bs-gray-600);}
time.ago > small { color:var(--bs-gray-600); padding-left:0.2rem;}


i.nav-icon {width:var(--icon-size);height:var(--icon-size);overflow:hidden;display:inline-block;background:transparent no-repeat 100% 100%;background-size:cover; }
#sidebar:not(.collapsed) i.nav-icon {position:relative;top:0.5rem;}
i.nav-icon.home {background-image:url("https://s.cogle.io/i/nav/home-off.svg"); }
i.nav-icon.academy {background-image:url("https://s.cogle.io/i/nav/academy-off.svg"); }
i.nav-icon.popular {background-image:url("https://s.cogle.io/i/nav/popular-off.svg"); }
i.nav-icon.community {background-image:url("https://s.cogle.io/i/nav/community-off.svg"); }
i.nav-icon.insight {background-image:url("https://s.cogle.io/i/nav/insight-off.svg"); }
i.nav-icon.extra {background-image:url("https://s.cogle.io/i/nav/extra-off.svg"); }
i.nav-icon.yt {background-image:url("https://s.cogle.io/i/nav/yt.svg");}
i.nav-icon.twitter {background-image:url("https://s.cogle.io/i/nav/twitter.svg");}

i.nav-icon.home::after {content:"";background-image:url("https://s.cogle.io/i/nav/home.svg");width:0;height:0;overflow:hidden; }
i.nav-icon.academy::after {content:"";background-image:url("https://s.cogle.io/i/nav/academy.svg");width:0;height:0;overflow:hidden; }
i.nav-icon.popular::after {content:"";background-image:url("https://s.cogle.io/i/nav/popular.svg");width:0;height:0;overflow:hidden; }
i.nav-icon.community::after {content:"";background-image:url("https://s.cogle.io/i/nav/community.svg");width:0;height:0;overflow:hidden; }
i.nav-icon.insight::after {content:"";background-image:url("https://s.cogle.io/i/nav/insight.svg");width:0;height:0;overflow:hidden; }
i.nav-icon.extra::after {content:"";background-image:url("https://s.cogle.io/i/nav/extra.svg");width:0;height:0;overflow:hidden; }

a:hover i.nav-icon.home,
.active i.nav-icon.home {background-image:url("https://s.cogle.io/i/nav/home.svg"); }
a:hover i.nav-icon.academy,
.active i.nav-icon.academy {background-image:url("https://s.cogle.io/i/nav/academy.svg"); }
a:hover i.nav-icon.popular,
.active i.nav-icon.popular {background-image:url("https://s.cogle.io/i/nav/popular.svg"); }
a:hover i.nav-icon.community,
.active i.nav-icon.community {background-image:url("https://s.cogle.io/i/nav/community.svg"); }
a:hover i.nav-icon.insight,
.active i.nav-icon.insight {background-image:url("https://s.cogle.io/i/nav/insight.svg"); }
a:hover i.nav-icon.extra,
.active i.nav-icon.extra {background-image:url("https://s.cogle.io/i/nav/extra.svg"); }


#toggle-sidebar {
	position:relative;
  width:30px;
  height:30px;
  min-width:30px;
  min-height:30px;
  margin:0;
  padding:0.25rem;
	border-radius:50%;
	display:flex;
	justify-content:center;
	align-items:center;
	border:none;
	margin-right:0.5rem;
	background-color:var(--bs-light);
	transition:.3s all ease;
	border:1px solid rgba(0,0,0,0.1);
}
#toggle-sidebar:active,
#toggle-sidebar:hover { background-color:var(--bs-warning);
	box-shadow: 0 2px 4px rgba(var(--theme-fg-rgb), 0.04), 0 0 4px rgba(var(--theme-bg-rgb), 0.9);
}

#toggle-sidebar .burger {
	display:block;
	width:calc(30px - 0.5rem);
	height:calc(30px - 0.5rem);
	background-image:url("https://s.cogle.io/i/burger.svg");
	background-size:cover;
	background-position:center;
	background-repeat:no-repeat;
}

.app.mobile #toggle-sidebar { margin-right:0;}

#sidebar.mobile .footer {
	display:flex;
	justify-content:end;
	margin-right:1rem;
	align-items:center;
	margin-top:2rem;
}
#sidebar ul.theme { position:relative;padding-left:0;}
#sidebar ul.theme > li { border:1px solid silver;border-radius:1rem;padding:0;margin-right:0.75rem;}
#sidebar ul.theme li button { display:flex;align-items:center;padding:0.2rem 0.5rem;}
#sidebar ul.theme ul.dropdown-menu { padding:0;border:1px solid rgba(var(--theme-fg-rgb), 0.1);box-shadow: 0 2px 4px rgba(var(--theme-fg-rgb), 0.04), 0 0 4px rgba(var(--theme-bg-rgb), 0.9); }
#sidebar ul.theme ul.dropdown-menu button { padding:0.4rem 0.75rem;}



.pagination { margin:2rem auto 2rem auto; }


ul.pagination { align-items:center; }

.pagination .page-item + .page-item:not(.next) { margin-left:0.25rem; }
.page-item.prev { margin-right:-0.1rem; }
.page-item.next { margin-left:-0.05rem; }


.page-link.disabled { color:lightgray;background-color:transparent !important;border:1px solid transparent; }
.page-item .page-link {
	min-width:2rem;
	margin-left: 0.2rem;
	padding:0.2rem 0.2rem;
}

.page-item.prev .page-link, 
.page-item.next .page-link {
	border-color:transparent;
	background-color:var(--theme-fg);
	mask-repeat:no-repeat;
	mask-size:60%;
	mask-position:center;
}

.page-item.prev .page-link,
.page-item.next .page-link { background:transparent; }

.page-link.disabled:focus { box-shadow:none; }

.pagination.more {display:block;width:100%;position:relative;text-align:center;}
.pagination.more > hr { border:0;height:1px;background-image: linear-gradient(to right, rgba(var(--theme-fg-rgb),0),rgba(var(--theme-fg-rgb),0.75), rgba(var(--theme-fg-rgb),0));position:relative;top:1rem;}
.pagination.more div { display:flex;justify-content:center;align-items:center; }
.pagination.more .carot { width:24px;height:24px; margin-left:0.25rem;
	background-color:var(--theme-fg);
	mask-image:url("https://s.cogle.io/i/icon/more.svg");
	mask-repeat:no-repeat;
	mask-size:cover;
}
.pagination.more button { position:absolute;top:-0.2rem;left:50%;transform:translateX(-50%);display:flex; padding-left:1rem;padding-right:1rem;border:1px solid gray;background-color:var(--theme-bg);color:var(--theme-fg); }

.rank.badge { font-size:1.2rem;background-color:rgba(var(--bs-danger-rgb),0.1);color:var(--bs-danger); }

ul.currency > li {padding:0.25rem 0.5rem;display:flex;align-items:center; }
ul.currency small {padding-left:0.25rem; }
ul.currency small + span {padding-left:0.75rem;padding-right:0.75rem; }



#head-up { position:fixed;top:var(--header-height);right:1rem;white-space:nowrap;z-index:200; background-color:#292e38;border: 2px solid #1a1b1f;box-shadow: 1px 1px 4px rgba(127,127,191,0.4);border-radius:0 0 0.6rem 0.6rem;right:1rem;min-width:30rem; }
#head-up ul.crypto {display:flex;margin:0.25rem;align-items:center;padding:0.2rem 0 0.1rem 0.5rem;justify-content:center;}
#head-up ul.crypto.outdated { opacity:40%;}

.crypto li { margin-right:0.75rem;color:orange;font-size:1.05rem;font-weight:bolder;}
.crypto [data-currency]::before { content:attr(data-currency);font-weight:bolder;color:lavender;position:relative;}
.crypto [data-currency] > small { color:silver;font-size:0.7rem;margin-left:0.25rem; }
.crypto [data-change="+"] { color:lime;}
.crypto [data-change="-"] { color:red;}
/* ↗ ↘   ↓ ↑ */

#head-up ul.crypto li[data-change]::after { content:" "; display:inline-block;padding-left:0.15rem;mask-size:cover; mask-repeat:no-repeat;width:0.7rem;height:0.7rem; }
#head-up ul.crypto li[data-change="+"]::after { 
	background-color:limegreen;
	mask-image:url("https://s.cogle.io/i/icon/up.svg"); 
}
#head-up ul.crypto li[data-change="-"]::after { 
background-color:coral;
mask-image:url("https://s.cogle.io/i/icon/down.svg");
}

#head-up ul.crypto li.kimp { color:aqua;}
#head-up ul.crypto li.kimp > small { color:silver;font-size:0.8rem;}
#head-up ul.crypto li.kimp > span { color:silver;font-size:0.9rem;}


section.home h1 + .lead { margin-top:4rem; }
section > .container { padding-left:0; }

.social-media > a { display:block;width:48px;height:48px;background-color:white;border-radius:50%;overflow:hidden; }
.social-media > a img { display:block;width:48px;height:48px; }

.copyright img.heart {width: 1rem;height: 1rem;display: inline-block; }


.badge[data-live="yt"] { --bs-bg-opacity: 1; background-color:var(--bs-red); display:inline-flex; align-items:center;margin-right:0.5rem;padding:0.1rem 0.3rem;font-size:0.75rem; }
.badge[data-live="yt"]::before { 
	content:"";
	display:inline-block;
	width:1.1rem;height:1.1rem;
	margin-right:0.2rem;
	background-color:var(--bs-white);
	mask-image:url("https://s.cogle.io/i/icon/yt-live.svg");
	mask-size:cover;
	mask-position:center;
}


@keyframes show-layer {
  50% {z-index:1;}
  100% {z-index:1; }
}
@keyframes hide-layer {
  0% {z-index:1; }
  49.999% {z-index:1;}
}
@keyframes show-login {
  0% {background:#d7e7f1;transform:translate(40%, 10px);}
  50% {transform:translate(0, 0);}
  100% {background-color:#fff;transform:translate(35%, -20px);}
}
@keyframes hide-login {
  0% {background-color:#fff;transform:translate(35%, -20px);}
  50% {transform:translate(0, 0);}
  100% { background:#d7e7f1;transform:translate(40%, 10px);}
}
@keyframes show-signup {
  0% { background:#d7e7f1;transform:translate(-40%, 10px) scaleY(.8);}
  50% { transform:translate(0, 0) scaleY(.8); }
  100% { background-color:#fff; transform:translate(-35%, -20px) scaleY(1); }
}
@keyframes hide-signup {
  0% { background-color:#fff; transform:translate(-35%, -20px) scaleY(1); }
  50% { transform:translate(0, 0) scaleY(.8); }
  100% { background:#d7e7f1; transform:translate(-40%, 10px) scaleY(.8); }
}



button.btn.bookmark {
	margin:0 0 0 0.25rem;
	padding:0 0.75rem;
	border-radius:50rem;
	border:1px solid rgba(var(--theme-fg-rgb),0.2);
	display:flex;
	align-items:center;
}

.loading { display:flex;justify-content:center;width:100%;height:100%;align-items:center;height:20vh; }
.loader { width: 48px; height: 48px; border-radius: 50%; display: inline-block; border-top: 4px solid #FFF; border-right: 4px solid transparent; box-sizing: border-box; animation: rotation 1s linear infinite;color:transparent; }
.loader::after { content: ""; box-sizing: border-box; position: absolute; left: 0; top: 0; width: 48px; height: 48px; border-radius: 50%; border-left: 4px solid #FF3D00; border-bottom: 4px solid transparent; animation: rotation 0.5s linear infinite reverse; }

@keyframes rotation {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.wiggle { animation:wiggle 0.82s cubic-bezier(.36,.07,.19,.97) both;transform:translate3d(0, 0, 0);backface-visibility:hidden;perspective:1000px;}
@keyframes notify { 
	50% {background-color:#ccc;color:firebrick;}
	/*
	25%, 75% {transform: translate3d(-1px, 0, 0);}
	50% {transform: translate3d(0, 0, 0);}
	 */
}
@keyframes pending { 50% {background-color:rgba(64,64,64,0.5);} }
@keyframes wiggle{
	10%, 90% {transform: translate3d(-1px, 0, 0);}
	20%, 80% {transform: translate3d(2px, 0, 0);}
	30%, 50%, 70% {transform: translate3d(-4px, 0, 0);}
	40%, 60% {transform: translate3d(4px, 0, 0);}
}
@keyframes spin {100%{ transform:rotate(360deg); }}
@keyframes spin-reverse {100%{ transform:rotate(-360deg); }}


@keyframes rotation {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.avatar { display:inline-block;border-radius:50%; }
.avatar > img { border-radius:50%;border:1px solid rgba(var(--theme-bg-rgb),0.1); }

.capitalize { text-transform: capitalize; }
#off-canvas { marginLeft:260px}

.backdrop { position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(0, 0, 0, 0.5);z-index:99;opacity:0;visibility:hidden;transition:opacity 0.3s ease, visibility 0.3s ease; }
.backdrop.show { opacity:1;visibility:visible; }

#main > section.lost { 
	width:calc(100vw - var(--sidebar-width));
	height:calc(100vh - var(--header-height));
	margin:0;
	padding:0;
	color:var(--lost-fg);
	position:relative;
	overflow: hidden;
	transition: background-color 0.3s, color 0.3s;
	background-color: var(--lost-bg);
}

section.lost > .container { position:relative;width:100%;height:80vh;flex:1 1 auto;padding-left:6rem; }
section.lost h1 { color:var(--cogle-color);font-size:1.8rem;margin-top:4rem;margin-bottom:1rem; }
section.lost h1 + a { font-size:1rem; }

section.lost .whale {
	position:absolute;
	bottom:18vh;
	opacity: 0;
	transform: translateY(160px);
	animation: rise-up 1s ease-out forwards, swim 4s infinite ease-in-out 1s;
}
section.lost .whale > img { margin:10px auto;width:70vw;max-width: 400px; }

section.lost .waves {position: absolute;bottom: 0;left:0;right:0;height: 18vh;
	background: repeating-linear-gradient(
		120deg,
		var(--lost-bg),
		var(--lost-bg) 20px,
		var(--wave) 20px,
		var(--wave) 40px
		);
	animation: wave 20s infinite linear;
	background-size: 600px 400px;
	transition: background 0.3s;
}

@keyframes wave {
	from { background-position: 0 0; }
	to { background-position: 100vw 0; }
}

@keyframes rise-up {
	from { transform: translateY(160px); opacity: 0; }
	to { transform: translateY(0); opacity: 1; }
}

@keyframes swim {
	0%, 100% { transform: translateY(0) rotate(0deg); }
	50% { transform: translateY(-20px) rotate(5deg); }
}

section.chat { margin-top:3.5rem;width:100%;max-width:280px;position:fixed; }

section.chat .messages {
	height:100%;
	overflow:hidden;
	overflow-y:auto;
	margin-bottom:1rem;
}
section.chat ul { list-style-type:none;margin:0;padding:0;margin-bottom:0.75rem; }
section.chat li.message { padding:0.1rem 0; }



.chart.fng[data-fng] { width:288px;height:156px; display:flex;flex-direction:column;align-items:center;background-repeat:no-repeat;background-size:cover;justify-content:end; }
.chart.fng[data-fng] > p {margin:0;}
.chart.fng[data-fng] > p:first-child {flex:1 1 auto;display:flex;align-items:flex-end;font-size:3rem;font-weight:bold;}

.alt[data-alt-season] ul.chart { display:flex;width:100%; }
.alt[data-alt-season] ul.chart > li { width:25%; }
.alt[data-alt-season] ul.chart > li:first-child { height:6px;background-color:rgb(246, 136, 25);}
.alt[data-alt-season] ul.chart > li:nth-child(2) { height:6px;background-color:rgb(252, 219, 185);}
.alt[data-alt-season] ul.chart > li:nth-child(3) { height:6px;background-color:rgb(193, 204, 253)}
.alt[data-alt-season] ul.chart > li:nth-child(4) { height:6px;background-color:rgb(49, 86, 250);}
.alt[data-alt-season] div.legend { display:flex;justify-content:space-between;font-size:0.85rem;color:var(--bs-secondary-color);}
.alt[data-alt-season] h4 { font-size:3rem;}
.alt[data-alt-season] h4 small { font-size:1rem;color:var(--bs-secondary-color)}
.alt[data-alt-season] .on { position:relative;width:100%; }
.alt[data-alt-season] .on > span { position: absolute;top:-4px;width:24px;height:24px;background-color:var(--bs-black);border:4px solid var(--bs-white);border-radius:50%;transform:translate(-50%, -50%);z-index: 10;box-shadow:rgba(88, 102, 126, 0.12) 0px 0.69px 1.37px, rgba(88, 102, 126, 0.08) 0px 2.75px 16.47px;}

.dominance[data-dominance] ul.legend { display:flex; }
.dominance[data-dominance] ul.legend h4 { font-size:0.95rem;font-weight:normal; }
.dominance[data-dominance] ul.legend h4::before { content:" ";width:0.85rem;height:0.85rem;border-radius: 100%;display:inline-block;position:relative;top:1px;margin-right:0.25rem; }
.dominance[data-dominance] ul.legend li.btc h4::before {background-color:var(--coin-btc);}
.dominance[data-dominance] ul.legend li.eth h4::before {background-color:var(--coin-eth);}
.dominance[data-dominance] ul.legend li.alt h4::before {background-color:var(--coin-alt);}

.dominance[data-dominance] ul.legend li { margin-right:0.75rem;font-size:1.1rem;font-weight:bold; }
.dominance[data-dominance] ul.legend li p::after {content:"%";font-size:0.8rem;color:var(--bs-seconday-color);font-weight:normal;}
.dominance[data-dominance] ul.chart { display:flex;width:100%; }
.dominance[data-dominance] ul.chart > li:first-child {height:6px;background-color:var(--coin-btc);}
.dominance[data-dominance] ul.chart > li:nth-child(2) {height:6px;background-color:var(--coin-eth);}
.dominance[data-dominance] ul.chart > li:nth-child(3) {height:6px;background-color:var(--coin-alt);flex-grow:1;}

.marquee { overflow: hidden; white-space: nowrap; width: 100%;margin-bottom:0.5rem; }
.marquee > .content { display: inline-block; will-change: transform; }
.marquee ul.content { display: inline-flex; gap: 1rem; margin: 0; padding: 0; list-style: none; }
.marquee ul.content li { display:inline-flex;gap:0.2rem;align-items:center; }
.marquee ul.content > li:first-child { margin-left:1rem; }

.provider::after { content:" ";border-radius:50%;width:1rem;height:1rem;background-size:cover;background-repeat:no-repeat;border:0;display:inline-block; }
.provider.local::after { background-image:url("https://s.cogle.io/i/cogle.svg"); }
.provider.google::after { background-image:url("https://s.cogle.io/i/social/google.svg");width:0.9rem;height:0.9rem; }
.provider.facebook::after { background-image:url("https://s.cogle.io/i/social/facebook.svg"); }
.provider.kakao::after { background-image:url("https://s.cogle.io/i/social/kakaotalk.png"); }
.provider.naver::after { background-image:url("https://s.cogle.io/i/social/naver.png"); }
.provider.apple::after { background-image:url("https://s.cogle.io/i/social/apple.svg"); }

@keyframes marquee {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}

.not-found {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
	padding-top: 5%;
}

.not-found h2 {
	font-size: 2rem;
	margin-top: 4rem;
	color:var(--cogle-color);
}
.not-found p { margin-top: 2rem; }
.not-found a { text-decoration:underline;
	text-decoration-color: var(--bs-link-hover-color);
	text-decoration-thickness: .15rem;
	text-underline-offset: 0.3rem;
}


// Bootstrap SCSS 기능을 사용하기 위한 필수 설정
//@import "~bootstrap/scss/functions";
//@import "~bootstrap/scss/variables"; // Bootstrap 기본 변수를 가져옵니다.
//@import "~bootstrap/scss/variables-dark";

$gray: #8e887a;
$gray-dark: #403d38;
$gray-100: #f8f6f2;
$gray-200: #f6f1e9;
$gray-300: #dad3c3;
$gray-400: #b5b0a1;
$gray-500: #968e7e;
$gray-600: #8e887a;
$gray-700: #716d66;
$gray-800: #403d38;
$gray-900: #211f1c;

$primary: #4fc9da;
$secondary: #dad3c3;
$success: #b8d935;
$info: #4f55da;
$warning: #e8c444;
$danger: #f06445;
$light: #f8f6f2;
$dark: #211f1c;

$primary-rgb: 79, 201, 218;
$secondary-rgb: 218, 211, 195;
$success-rgb: 184, 217, 53;
$info-rgb: 79, 85, 218;
$warning-rgb: 232, 196, 68;
$danger-rgb: 240, 100, 69;
$light-rgb: 248, 246, 242;
$dark-rgb: 33, 31, 28;
$dropdown-padding-y: 0;
$dropdown-item-padding-y: 0.4rem;
$dropdown-link-hover-bg: rgba(191,191,191,0.1);
$pagination-disabled-color: rgba(191,191,191,0.7);


$font-size-base: 0.95rem;
$font-family-sans-serif: "oh", "Noto Sans CJK KR", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace: "JetBrains Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

$gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
$body-bg: #f8f9fa;
$body-bg-rgb: 248, 249, 250;
$code-color: #b93993;

$link-color: #211f1c;
$link-color-rgb: 33, 31, 28;
$link-hover-color: #d85a3e;

$cogle-color:#f06445;
$cogle-rgb: 240,100,69;
$cogle-border: rgba(240,100,69,0.5);


:root,
[data-bs-theme="light"] {
  --cogle-color: #{$cogle-color};
  --cogle-color-rgb: #{$cogle-rgb};
  --header-height: 50px;
  --sidebar-width: 240px;
  --aside-width: 320px;
  --sidebar-collapsed-width: 64px;
  --icon-size: 1.5rem;
  --theme-fg: #{$link-color};
  --theme-bg: #{$body-bg};
  --theme-fg-rgb: #{$link-color-rgb};
  --theme-bg-rgb: #{$body-bg-rgb};
  --max-width: 1140px;
  --card-bg: #fff;
  --coin-btc: #f68819; /*rgb(246, 136, 25)*/
  --coin-eth: #3861fb; /*rgb(56, 97, 251)*/
  --coin-alt: #{$gray-300};

	--bg-slant: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27rgb%28149.4, 222.6, 232.8%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");


	.lost {
		--lost-bg: #e8eff5;
		--lost-fg: #333;
		--lost-color: #336699;
		--wave: #d0dce8;
	}
  .card {
    --bs-card-bg: var(--card-bg);
  }

  .app.mobile {
    --max-width:calc(100vw - 2rem);
  }
  #sidebar.mobile {
    --sidebar-width:76vw;
    --sidebar-max-width:280px;
  }
  .pagination {
    --bs-pagination-active-bg: #{$cogle-color};
    --bs-pagination-active-border-color: #{$cogle-border};
  }
  .accordion-button {
    --bs-accordion-btn-padding-x:0.5rem;
    --bs-accordion-btn-padding-y:0.5rem;
  }
  #sidebar.collapsed + main .lost { --sidebar-width: 64px; }
}


[data-bs-theme="dark"] {
  --theme-fg: #{$body-bg};
  --theme-bg: #{$link-color};
  --theme-fg-rgb: #{$body-bg-rgb};
  --theme-bg-rgb: #{$link-color-rgb};
  --card-bg: #1b1a10;
	.lost {
		--lost-bg: #1e1e1e;
		--lost-fg: #ffffff;
		--lost-color: #5588cc;
		--wave: #3a3a3a;
	}
  .card {
    --bs-card-bg: var(--card-bg);
  }

  a {
    --bs-link-hover-color: #{$cogle-color};
  }
}

button {
  color: $link-color;
  &:hover {
    color: $link-hover-color;
  }
}


.modal.member .btn-danger { 
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bs-red); 
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-color: var(--bs-success);
}
#masthead.alt .nav-link {
  --bs-nav-link-color: var(--bs-white);
  --bs-nav-link-hover-color: var(--cogle-color); 
}

#sidebar .accordion-button:not(.collapsed)::after {
	background-image:none;
	mask-image: var(--bs-accordion-btn-active-icon);
	background-color: var(--cogle-color);
}

#sidebar .dropend .dropend .accordion-button::after {
  --bs-accordion-btn-icon-width: 1rem;
}

@import "~bootstrap/scss/bootstrap";
ul { list-style-type:none;margin:0;padding:0; }




section.post .card {
	--bs-card-spacer-x: 0.5rem;
	--bs-card-spacer-y: 0.5rem;
	--bs-card-cap-padding-x: 0.5rem;
	--bs-card-cap-padding-y: 0.5rem;
}


section.post article.hentry.card {
	border: 1px solid transparent;
}

section.post h1 { display:flex;justify-content:space-between;align-items:baseline; }
section.post h1 button { font-size:0.9rem;padding:0.2rem 0.75rem; }

section.post article h3 { font-size:1.2rem;color:var(--theme-fg); }
section.post article h3 small { font-size:0.75rem; }
section.post .modal { margin-top:var(--header-height); }

section.post ul.attachment h4 { font-size:1rem; }
section.post ul.attachment li.file { display:flex;align-items:center;white-space:nowrap; }
section.post ul.attachment li.file a {display:flex;align-items:center;max-width:100%;color:var(--bs-secodndary);} 
section.post ul.attachment .ellipsis { flex:1 1 auto;display:inline-block; }
section.post ul.attachment .size {margin-left:1rem; }
section.post ul.attachment .size::before { content:"("; font-size:0.8rem; color:var(--bs-secondary); }
section.post ul.attachment .size::after { content:")"; font-size:0.8rem; color:var(--bs-secondary); }

section.post .browse { display: inline-block; position: relative; cursor: pointer; }
section.post article time { white-space:nowrap; }

section.post .modal input[name="title"] { margin-bottom:0.5rem; }
section.post .modal .browse input[type="file"] { position: absolute;opacity: 0;top: 0;left: 0;width: 100%;height: 100%;cursor: pointer; }
section.post .browse button { margin-top:0.5rem;margin-bottom:0.5rem; padding-left:0.75rem;padding-right:0.75rem; }
section.post > :not(.comment) .DraftEditor-root {height:45vh;min-height:8rem;overflow-x:hidden;overflow-y:auto;}
section.post .comment .DraftEditor-root {overflow-x:hidden;overflow-y:auto;border-bottom:1px solid rgba(var(--bs-body-color-rgb),0.4);}

section.post .hentry.card { --card-bg:transparent; }
section.post .hentry.card .card-footer { background-color:transparent; }
section.post .hentry.card .card-footer .input-group { justify-content:flex-end; }
/*section.post .hentry.card { --card-bg:var(--bs-card-cap-bg); } */
/*section.post .hentry.card .card-header { --bs-card-border-color:transparent;padding-bottom:0; } */
section.post .hentry.card .card-body { padding-top:0;padding-bottom:0; }

section.post .entry-content figure > img { max-width:80vw; }

.comment > .header { display:flex;margin-top:1rem; }

form[name="add-comment"] { display:flex;align-items:baseline; }
form[name="add-comment"] .editor { flex:1 1 auto;margin-left:0.5rem;}
form[name="add-comment"] .action { text-align:right;margin-top:0.25rem;}
form[name="add-comment"] .action .btn { padding:0.25rem 1rem;margin-left:0.5rem;}

ul.feedback { display:flex;align-items:center;justify-content:space-between;list-style-type:none;padding:0;margin:0 0 0.5rem 0; }
ul.feedback > li:first-child { flex:1 1 auto;}
ul.feedback .navbar li { padding-left:0.5rem; }

ul.feedback ul.entry-meta {display:flex;align-items:center; }
ul.feedback ul.entry-meta > li:last-child {display:flex;flex-direction:column;margin-left:0.5rem; }
ul.feedback ul.entry-meta .published {display:flex;align-items:center;}
ul.feedback ul.entry-meta .published time {margin-left:0.25rem;}

ul.feedback > li:last-child {display:flex;align-items:center; }
ul.feedback > li:last-child button.btn { border-radius:50rem;padding:1px 0;padding:0.1rem 0.75rem;font-size:0.95rem;display:block; }

.card-footer div[id^="remove-"] > div { padding:0.5rem 1rem; }
.card-footer div[id^="remove-"] { position:absolute;z-index:999;right:0;bottom:2.5rem;white-space:nowrap;text-align:right;background-color:var(--theme-bg);border:1px solid var(--bs-danger); color:var(--theme-fg); }
.card-footer div[id^="remove-"] .input-group { padding-right:2rem;justify-content:flex-end;margin-bottom:0.5rem; }

ul.comment { margin-top:0.75rem }
ul.comment .btn-sm { --bs-btn-padding-y : 0.1rem; }
ul.comment form { margin:0.5rem; }
ul.comment li > dl { margin:0.25rem 0; /*border:1px solid rgba(var(--theme-fg-rgb),0.1);border-radius:0.5rem;*/padding:0.5rem 0.75rem; }
ul.comment li > dl  dd:not(.reaction) { margin-left:0.5rem;margin-bottom:0;}
ul.comment dd > p:last-child { margin-bottom:0;}
ul.comment li.tab-00 { padding-left:0}
ul.comment li.tab-01 { padding-left:2rem;}
ul.comment li.tab-02 { padding-left:4rem;}
ul.comment li.tab-03 { padding-left:8rem;}
ul.comment li dt { font-weight:normal; }

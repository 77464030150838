@media screen and (max-width: 769px) {

	#header + .container {padding-left:0;padding-right:0;}
	article.card .card-header,
	article.card .card-body, 
	article.card .card-footer { padding-left:0.5rem; }

	#head-up { top:auto;bottom:0;left:0;right:0;width:100%;box-shadow: 1px 1px 1px rgba(127,127,191,0.2);border-radius:0;min-width:0;padding-top:0.2rem; }
	#head-up ul.crypto { justify-content:space-between;height:2rem;align-items:flex-start;margin:0 0.3rem 0.25rem 0.3rem; }
	#head-up ul.crypto li { letter-spacing:0;font-size:0.88rem;position:relative;}
	#head-up ul.crypto.btc::before { position:relative;top:0.2rem; }
	#head-up ul.crypto li[data-currency] > small { margin-left:0;position:absolute;top:1.1rem;right:0.8rem;letter-spacing:0; }
	#head-up ul.crypto li[data-currency] small > span { font-size:80%;padding-left:0.1rem; }
	#head-up ul.crypto li.kimp { padding-top:0.3rem; }
	#head-up ul.crypto li[data-change]::after { left:0.35rem;position:relative;}
	#head-up ul.crypto li[data-change]:not(.kimp)::after { top:0.35rem; }

	.dropzone.dz-clickable { min-height:64px; }
	section > .container > ul.row {padding-left:0; }

	.modal.member { font-size:0.9rem; }
	.modal.member .modal-body { padding-bottom:2rem; }

	#sign-in .panel form { margin-right:1.5rem;padding: 0.75rem 0 0 0.5rem; }
	#sign-up.modal.member .panel { margin-right:1rem; }
	.modal.member .panel .input-group .input-group-text:first-child { min-width:2rem; }
	.modal.member .panel button[type="submit"] { left: 0.25rem; }
	.modal.member .hero { order:1;width:60%; }

	#sidebar .profile { margin-top:0.25rem; }

	#main > section:not(.lost) + footer { padding-bottom:4rem; }
	#main > section.lost { width:100vw; }
	section.lost > .container { text-align:center;padding-left:0; }
	section.lost h1 { font-size:1.2rem; }
	section.lost .whale { width:100vw; display:flex; justify-content:center; }
	section.post ul.feedback { flex-direction:column;align-items:flex-start; }


	#footer {margin-bottom:1rem; }
	#footer .container li { margin-bottom:0.5rem; }
	#footer ul.sns {display:inline-flex;gap:0.25rem;margin:0.4rem 0; }
	#footer .copyright {display:block;line-height:150%; }
	#footer ul.about { display:inline-flex;gap:0.5rem;margin-bottom:0.5rem; }
}

@media (min-width: 1200px) { 
	/*.container, .container-lg, .container-md, .container-sm, .container-xl { max-width:1280px; }  */
	.container, .container-lg, .container-md, .container-sm, .container-xl { max-width:100%; }
}

@media (max-width:991.98px) {
	.modal.member section > .container { padding:0 0.25rem 0 1rem;flex-direction:column; }
	.modal.member .modal-body > section:first-child .container { margin-top:0; }
	.modal.member section.member form[name="login"] { width:auto; }
	.modal.member section.member .hero { bottom:-80px;margin-bottom:80px; } 
	.modal.member .container.auth section { flex-direction:column;align-items:center; }
	.modal.member .container.auth section > div.auth { max-width:90%; }
	.modal.member section.sign-in { padding-top:2rem; }

	.modal.member .hero { order:1;width:70%; }
	.modal.member .panel { order:2;position:relative;top:-0.25rem; }
}

